<template>
  <div>
    <div>
      <div>
        <notification-list-item
          v-for="(notification, index) in filteredNotifications"
          :key="'notification-today-' + index + '-' + notification.id"
          :notification="notification"
        />
        <b-list-group-item
          v-show="filteredNotifications.length === 0"
          class="notification-item border-0"
        >
          <div>
            No hay notificaciones por mostrar
          </div>
        </b-list-group-item>
        <b-list-group-item
          v-show="filteredNotifications.length > 0 && !loading"
          class="notification-item border-0"
        >
          <div class="text-center">
            <z-button
              v-show="!pagination.done"
              class="w-100"
              @click="getNotifications"
            >
              Cargar más
            </z-button>
            <p v-show="pagination.done">Llegaste al final de la lista</p>
          </div>
        </b-list-group-item>
        <b-list-group-item v-show="loading" class="loading-container">
          <div>
            <loading-spinner />
          </div>
        </b-list-group-item>
      </div>
    </div>
  </div>
</template>

<script>
import Logs from "@/services/logs";
import notifyMixin from "@/mixins/notify";
import NotificationListItem from "./NotificationListItem";
import LogsSeverity from "@zubut/common/src/constants/logs/severity";

export default {
  name: "NotificationList",

  components: {
    NotificationListItem
  },

  mixins: [notifyMixin("notifications")],

  props: {
    from: {
      validator: prop =>
        ["string", "object"].includes(typeof prop) || prop === null,
      required: true
    },
    until: {
      validator: prop =>
        ["string", "object"].includes(typeof prop) || prop === null,
      required: true
    },
    search: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "custom",
      validator: prop => ["today", "custom"].indexOf(prop) !== -1
    }
  },

  data() {
    return {
      loading: true,
      notifications: [],
      pagination: {
        skip: 0,
        limit: 50,
        done: false
      },
      unsuscribe: null
    };
  },

  computed: {
    isValidFilter() {
      if (this.from && this.until) {
        return true;
      }
      return false;
    },
    filteredNotifications() {
      if (!this.isValidFilter) return [];
      return this.notifications.filter(
        item => item.message?.toLowerCase().indexOf(this.search) !== -1
      );
    }
  },

  watch: {
    from() {
      this.getNotifications();
    },
    until() {
      this.getNotifications();
    }
  },

  beforeMount() {
    this.getNotifications();
  },

  mounted() {
    if (this.type === "today") {
      this.unsuscribe = this.$store.subscribe(mutation => {
        if (mutation.type === "notification/addNotification") {
          this.handleNotificationMutation(mutation.payload);
        }
      });
    }
  },

  beforeDestroy() {
    if (this.unsuscribe) this.unsuscribe();
  },

  methods: {
    getNotifications() {
      this.loading = true;
      Logs.getLogs({
        from: this.from,
        until: this.until,
        skip: this.pagination.skip,
        limit: this.pagination.limit
      })
        .then(response => {
          if (response.data.length) {
            this.pagination.skip += this.pagination.limit;
            this.notifications = [...this.notifications, ...response.data];
          } else {
            this.pagination.done = true;
          }
        })
        .catch(err => {
          this.$captureError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleNotificationMutation(notification) {
      this.showNotificationAlert(notification);
      this.notifications = [notification, ...this.notifications];
    },
    reset() {
      this.notifications = [];
      this.pagination = {
        skip: 0,
        limit: 1,
        done: false
      };
    },
    showNotificationAlert(notifcation) {
      if (notifcation) {
        let type = 0;

        switch (notifcation.severity) {
          case LogsSeverity.NUM_ERROR:
            type = LogsSeverity.ERROR;
            break;
          case LogsSeverity.NUM_WARN:
            type = LogsSeverity.WARN;
            break;
          case LogsSeverity.NUM_INFO:
          default:
            break;
        }
        const notifications = JSON.parse(localStorage.getItem("notifications"));
        if (notifications) {
          this.notify({
            text: notifcation.message,
            title: notifcation.title,
            type
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.loading-container {
  padding-top: 2em;
}

.notification-item:last-child {
  border: none !important;
}

::v-deep .list-group-item {
  padding: 0.75rem 0;
}
</style>
