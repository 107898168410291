<template>
  <div class="notification-list-separator">
    <span>{{ date }}</span>
  </div>
</template>

<script>
import moment from "moment";
import DateConstants from "@/constants/filters/date";

export default {
  name: "NotificationListSeparator",
  props: {
    dateType: {
      required: true,
      validator: value =>
        [
          DateConstants.NUM_TODAY,
          DateConstants.NUM_YESTERDAY,
          DateConstants.NUM_THIS_WEEK,
          DateConstants.NUM_CUSTOM
        ].indexOf(value) !== -1
    }
  },
  data() {
    return {
      date: ""
    };
  },
  watch: {
    dateType: {
      immediate: true,
      handler: "formatDate"
    }
  },
  methods: {
    formatDate() {
      if (this.dateType === DateConstants.NUM_TODAY) {
        this.date = moment().format("[Hoy, ] D MMMM");
      } else {
        this.date = DateConstants.get[this.dateType];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.notification-list-separator {
  font-size: 16px;
  font-weight: 500;
  color: $black;
}
</style>
