<template>
  <b-list-group-item class="notification-item">
    <p class="message">
      {{ notification.message }}
    </p>
    <div>
      <WarningIcon
        v-if="notification.severity === logsSeverity.NUM_WARN"
        class="notification-icon warning-icon"
      />
      <WarningIcon
        v-else-if="notification.severity === logsSeverity.NUM_ERROR"
        class="notification-icon error-icon"
      />
      <UserIcon v-else class="notification-icon user-icon" />
      <small class="notification-time">{{ notification.parsedDate }}</small>
    </div>
    <hr />
  </b-list-group-item>
</template>

<script>
import UserIcon from "@/assets/icons/ic_man_user.svg";
import WarningIcon from "@/assets/icons/ic_warning.svg";
import LogsSeverity from "@zubut/common/src/constants/logs/severity";

export default {
  name: "NotificationDialogItem",

  components: {
    UserIcon,
    WarningIcon
  },

  props: {
    notification: {
      type: Object,
      required: true
    }
  },

  computed: {
    logsSeverity() {
      return LogsSeverity;
    }
  }
};
</script>

<style lang="scss" scoped>
.notification-item {
  border-radius: 0px !important;
  border: none !important;
  margin-bottom: 1px;

  hr {
    margin-bottom: 4px;
  }

  .message {
    font-size: 12px;
    padding: 0;
    margin: 0;
  }

  .notification-time {
    margin-left: 0.5em;
    color: $nobel;
  }

  .notification-icon {
    height: 0.8em;
  }

  .user-icon {
    fill: $zubut-blue;
  }

  .error-icon {
    fill: $red;
  }

  .check-mark-icon {
    fill: $zubut-blue;
  }

  .warning-icon {
    fill: $orange;
  }

  .box-icon {
    fill: $zubut-blue;
  }

  .placeholder-icon {
    fill: $zubut-blue;
  }
}

.notification-item:last-child {
  border-bottom: none !important;
}
</style>
