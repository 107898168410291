<template>
  <div class="notification-container">
    <div class="notification-sticky pb-3">
      <div
        class="notifications-header d-flex align-items-center justify-content-between"
      >
        <div class="d-flex">
          <h1 class="notifications-title">
            Notificaciones
          </h1>
          <b-form-checkbox
            class="ml-2 mb-1"
            switch
            size="lg"
            :checked="defineNotificationActive"
            @input="changeStatus"
          />
        </div>

        <div class="d-flex">
          <div
            v-tooltip.bottom="'Configurar'"
            class="update-btn"
            @click="$emit('open-settings')"
          >
            <font-awesome-icon icon="notification-settings" />
          </div>
          <div class="update-btn" @click="refreshNotifications">
            <font-awesome-icon icon="refresh" />
          </div>
        </div>
      </div>
      <hr />
      <quick-message
        class="mb-3"
        with-icon
        :show.sync="hasMessage"
        :message="message"
        type="success"
      />
      <div class="d-flex justify-content-between w-100">
        <div class="search-filter d-flex align-items-center flex-grow-1">
          <font-awesome-icon class="icon" icon="search" />
          <b-form-input
            v-model="searchText"
            class="flex-fill"
            placeholder="Buscar"
            size="sm"
            type="search"
          />
        </div>
        <div class="flex-grow-1">
          <z-dropdown
            v-model="dateFilter"
            :options="dateOptions"
            variant="white"
            placeholder="Fecha"
            :show-header="false"
            class="w-100"
          />
        </div>
      </div>
      <div
        v-if="showCustomDateFilters"
        class="d-flex justify-content-around mt-2"
      >
        <div>
          <z-date-picker
            v-model="customFrom"
            calendar-class="date-picker"
            input-class="form-control bg-white"
            placeholder="Desde"
          ></z-date-picker>
        </div>
        <div class="d-flex align-items-center mx-2">
          <span>-</span>
        </div>
        <div>
          <z-date-picker
            v-model="customUntil"
            calendar-class="date-picker"
            input-class="form-control bg-light"
            placeholder="Hasta"
          ></z-date-picker>
        </div>
      </div>
    </div>

    <div class="notifications-list-container-row">
      <div class="notifications-list-container-col">
        <div>
          <notification-list-separator
            :date-type="dateFilterConstant.NUM_TODAY"
          />
        </div>
        <notification-list
          :key="todayNotifications.id"
          type="today"
          :from="formatToISO(todayNotifications.from)"
          :until="formatToISO(todayNotifications.until)"
          :search="searchText"
        />
        <div v-show="showCustomDateNotifications">
          <b-row>
            <b-col>
              <notification-list-separator :date-type="dateFilter" />
            </b-col>
          </b-row>
          <div v-if="customNotifications.from && customNotifications.until">
            <notification-list
              :key="customNotifications.id"
              :from="formatToISO(customNotifications.from)"
              :until="formatToISO(customNotifications.until)"
              :search="searchText"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateRange, formatToISO } from "@zubut/common/src/utils/time";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import notifyMixin from "@/mixins/notify";
import DateConstants from "@/constants/filters/date";
import ZDatePicker from "@zubut/common/src/components/ZDatePicker";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import NotificationList from "./NotificationList";
import NotificationListSeparator from "./NotificationListSeparator";

export default {
  name: "Notifications",

  components: {
    NotificationList,
    NotificationListSeparator,
    ZDatePicker,
    ZDropdown
  },

  mixins: [notifyMixin("notifications")],

  data() {
    return {
      dateOptions: [
        { value: DateConstants.NUM_TODAY, text: DateConstants.TODAY },
        {
          value: DateConstants.NUM_YESTERDAY,
          text: DateConstants.YESTERDAY
        },
        {
          value: DateConstants.NUM_THIS_WEEK,
          text: DateConstants.THIS_WEEK
        },
        { value: DateConstants.NUM_CUSTOM, text: DateConstants.CUSTOM }
      ],
      dateFilterConstant: DateConstants,
      todayNotifications: {
        id: 0,
        from: startOfDay(new Date()),
        until: endOfDay(new Date())
      },
      customNotifications: {
        id: 0,
        from: null,
        until: null
      },
      searchText: "",
      hasMessage: false,
      message: "",
      formatToISO
    };
  },

  computed: {
    dateFilter: {
      get() {
        return this.$store.state.notificationDialog.dateFilter;
      },
      set(newDateFilter) {
        this.$store.commit("setNotificationDialog", {
          dateFilter: newDateFilter
        });
      }
    },
    newNotification() {
      return this.$store.state.request.latestNotification;
    },
    showCustomDateFilters() {
      if (
        this.dateFilter === DateConstants.NUM_CUSTOM &&
        this.customNotifications
      ) {
        return true;
      }
      return false;
    },
    showCustomDateNotifications() {
      return this.dateFilter !== DateConstants.NUM_TODAY;
    },
    defineNotificationActive() {
      const notifications = JSON.parse(localStorage.getItem("notifications"));

      if (notifications === null) {
        localStorage.setItem("notifications", false);
        return false;
      } else {
        return notifications;
      }
    },
    customFrom: {
      get() {
        return this.customNotifications.from;
      },
      set(newVal) {
        this.customNotifications.from = newVal;
      }
    },
    customUntil: {
      get() {
        return this.customNotifications.until;
      },
      set(newVal) {
        this.customNotifications.until = newVal;
      }
    }
  },

  watch: {
    newNotification(newVal) {
      this.showNotificationAlert(newVal);
    },
    dateFilter() {
      this.setCustomDate();
    },
    "customNotifications.from": function() {
      this.customNotifications.id++;
    },
    "customNotifications.until": function() {
      this.customNotifications.id++;
    }
  },

  methods: {
    refreshNotifications() {
      // This refreshes the notifications list component
      this.todayNotifications.id++;
    },
    setCustomDate() {
      if (this.dateFilter === DateConstants.NUM_CUSTOM) {
        this.customNotifications.from = startOfDay(new Date());
        this.customNotifications.until = endOfDay(new Date());
      } else {
        const date = dateRange(this.dateFilter);
        this.customNotifications.from = date.from;
        this.customNotifications.until = date.until;
      }
    },
    changeStatus() {
      const currentNotificationStatus = JSON.parse(
        localStorage.getItem("notifications")
      );

      const notificationStatusAfterChange = !currentNotificationStatus;

      this.message =
        notificationStatusAfterChange === false
          ? "Notificaciones desactivadas"
          : "Notificaciones activadas";

      localStorage.setItem(
        "notifications",
        JSON.stringify(notificationStatusAfterChange)
      );
      this.hasMessage = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.notification-container {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 70px);
  min-width: 320px;
}

.notification-sticky {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: $white;
}

.notifications-header {
  .notifications-title {
    margin: 0;
    font-size: 20px;
    color: $black;
    font-weight: 500;
  }

  .update-btn {
    height: 28px;
    width: 28px;
    margin: 0 2px;
    cursor: pointer;
    font-size: 1.6rem;
    color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.search-filter {
  cursor: text;
  background-color: $white;
  border: 1px solid $gainsboro;
  border-radius: 0.25rem;
  overflow-x: hidden;
  margin-right: 4px;
  padding-left: 8px;

  .icon {
    font-size: 1rem !important;
    color: $nobel;
  }

  input {
    cursor: text;
    color: $nero;
    min-height: 32px;
    font-size: 12px;
    border-color: transparent;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.notifications-list-container-row {
  margin-top: 16px;
  margin-left: 0px;
  margin-right: 0px;
  padding-bottom: 2.6px;

  .notifications-list-container-col {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px;
    margin-bottom: 2.6px;
  }
}
</style>

<style lang="scss">
@import "../../../styles/date-picker.scss";
</style>
