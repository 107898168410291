<template>
  <div>
    <go-back
      title="Configurar notificaciones"
      @back-action="$emit('hide-settings')"
    />
    <hr />
    <quick-message
      class="mb-3"
      with-icon
      show
      message="Esta funcionalidad sigue bajo desarrollo"
    />
    <p class="m-0 notification-setting-title">Establecer horario</p>
    <p class="m-0 mt-1 notification-setting-text">
      Solo recibirás notificaciones durante el horario programado.
    </p>

    <b-form-group class="mt-4" label="Recibir notificaciones" label-size="sm">
      <b-form-select
        v-model="selectedOption"
        :options="notificationOptions"
        name="notification-settings"
      />
    </b-form-group>

    <div class="d-flex justify-content-between mt-2">
      <b-form-select v-model="selectedStartTime" :options="startTimeOptions" />
      <div class="d-flex align-items-center mx-2">
        <span> a </span>
      </div>
      <b-form-select v-model="selectedEndTime" :options="endTimeOptions" />
    </div>

    <div class="d-flex flex-column flex-sm-row mt-5">
      <z-button
        variant="secondary"
        class="z-action w-auto mr-sm-3"
        @click="$emit('hide-settings')"
      >
        Cancelar
      </z-button>
      <z-button class="z-action w-auto mt-3 mt-sm-0">
        Guardar
      </z-button>
    </div>
  </div>
</template>

<script>
import GoBack from "@zubut/common/src/components/GoBackTitle";

export default {
  components: {
    GoBack
  },

  data() {
    return {
      notificationOptions: [
        { text: "Todos los días", value: 0 },
        { text: "Entre semana", value: 1 }
      ],
      selectedOption: 0,
      selectedStartTime: 9, // 9 am
      selectedEndTime: 21, // 9 pm
      timeOptions: this.createTimeOptions()
    };
  },

  computed: {
    startTimeOptions() {
      return this.timeOptions.reduce((arr, item) => {
        arr.push(item);
        return arr;
      }, []);
    },
    endTimeOptions() {
      return this.timeOptions.reduce((arr, item) => {
        if (item.value <= this.selectedStartTime) {
          arr.push({ ...item, disabled: true });
          return arr;
        }
        arr.push(item);
        return arr;
      }, []);
    }
  },

  methods: {
    createTimeOptions() {
      const res = [];
      for (let i = 0; i <= 24; i++) {
        let meridiem = i >= 12 ? "pm" : "am";
        let hour = i > 12 ? i - 12 : i;

        res.push({ value: i, text: `${hour}:00 ${meridiem}` });

        if (i !== 24) {
          res.push({ value: i + 0.5, text: `${hour}:30 ${meridiem}` });
        }
      }
      return res;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .z-card-title {
  font-weight: 500 !important;
}

.notification-setting-text {
  font-size: 12px;
}

.notification-setting-title {
  font-size: 14px;
  font-weight: 500;
}
</style>
